import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Instagram from '../../icons/instagram.svg'
import Feed1 from "../../images/what-the-tech-feed1.png"
import Feed2 from "../../images/what-the-tech-feed3.png"
import Demo from "../../images/what-the-tech-demo.mp4"
import Changes from "../../images/what-the-tech-changes.png"
import InstagramEmbed from 'react-instagram-embed'

const WhatTheTech = () => (
  <Layout>
    <SEO title="what the tech" />
    <div className="c12 title main">
        <h1>What the Tech</h1>
    </div>
    <div className="c12 main">
        <div className="summary">
            <div className="summary-section">
                <div className="section summary-item">
                    <h5 className="subtitle">Overview</h5>
                    <p>
                        An Instagram account that makes tech more understandable and fun for non-techies.
                    </p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Project Type</h5>
                    <p>Voluntary, outside of work</p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Roles</h5>
                    <p>Creative director</p>
                    <p>Content designer & creator</p>
                    <p>Social media strategist</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Timeline</h5>
                    <p>Ongoing project started March 2019</p>
                </div>
                <div className="section summary-item">
                    <a target="_blank" href="https://www.instagram.com/_whatthetech/" className="summary-section">
                        <img src={Instagram} className="summary-icon" />
                    </a>
                </div>
            </div>
            <video autoPlay loop muted playsInline src={Demo} className="summary-section"></video>
        </div>
    </div>
    <div className="grid">
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">Background</h5>
                <h2 className="tech-title">Tech for non-techies</h2>
                <p>
                Before pivoting into tech, someone could've simply said the word "algorithm" and it would've impressed me. Once I learned more about these technical topics, I intuitively connected them with familiar concepts. I shared some of these analogies with my non-technical coworkers, friends, and family to explain the features I was working on. They were excited, engaged, and often had follow-up questions.
                </p>
                <br/>
                <p>
                I started What the Tech to share these analogies and help bridge the gap between software engineers and the rest of the world. I'm passionate about getting more diverse talent in technical roles. If tech had been presented as more accessible and fun, I might have considered pursuing it as a degree. It's my hope to cultivate more diversity in tech by breaking down these barriers.
                </p>
            </div>

            <div className="section">
                <h5 className="subtitle">Problem</h5>
                <h2 className="tech-title">Tech is intimidating</h2>
                <ul>
                    <li>Many unique buzzwords are exclusively used in tech and are often intimidating due to their lack of exposure in TV shows, pop culture, etc.</li>
                    <li>Others who were also not in the tech world expressed feeling a large gap preventing them from entering or being curious about the field.</li>
                    <li>Lack of exposure causes miscommunication between engineers and others in the industry such as product, sales, and marketing.</li>
                </ul>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Goals</h5>
                <h2 className="tech-title">Who the tech cares?</h2>
                <ul>
                    <li>Make tech more approachable</li>
                    <li>Inspire more people, especially women, to consider pursuing a technical career</li>
                    <li>Empower others in tech to have a deeper understanding of the products they are selling, marketing, and pitching</li>
                    <li>Improve communication efficiency between software engineers and others in the company</li>
                </ul>
            </div>

            <div className="section">
                <h5 className="subtitle">Design</h5>
                {/* <h2 className="tech-title">Tech, but relatable</h2> */}
                <p>
                I designed the posts to be connected like a puzzle, which adds an element of cohesion between the variety of posts. The circuit board wires add a "techie" feel to the feed while still maintaining a playful, light, and approachable appearance. I created all of the posts using Canva.
                </p>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Feed1} className="img"/>
                    </div>
                    <div className="">
                        <img src={Feed2} className="img"/>
                    </div>
                </div>
                {/* <img src={Feed1} className="feed-images"/>
                <img src={Feed2} className="feed-images"/> */}
            </div>
            <div className="section">
                <h5 className="subtitle">Iterate</h5>
                <p>I experimented with different types of content and designs: adding videos, having the analogy in the post's caption vs in multi-image posts, etc. I tracked the posts' success through their engagement and share count. I also asked users directly for feedback on the content via Instagram stories.</p>
                <br/>
                <p>After the first five analogy posts, I started putting the analogy as a teaser on the first photo of the post. So, instead of seeing just "API," it'd say "API: it's like a vending machine" which piqued people's curiosity and I began to see more growth and engagement on the platform.</p>
                <br/>
                <p>I also tried creating posts related to current topics, trends, or events vs evergreen (not time-bound) content. I found that the former was far more likely to get shared by users, and therefore people connected to it more, and in turn, it brought in more engagement and reach. </p>
                <img src={Changes} className="img"/>
            </div>
            <div className="section">
                <h5 className="subtitle">Execute</h5>
                <p>Here are two examples of analogy posts:</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <InstagramEmbed url='https://www.instagram.com/p/CA2wMMagV_X/' hideCaption={true} clientAccessToken='2824026237882163|95a0a06c5748fd3e2641f54bf1599a35'/>
                    </div>
                    <div className="">
                        <InstagramEmbed url='https://www.instagram.com/p/BwXaPGFhVC5/' hideCaption={true} clientAccessToken='2824026237882163|95a0a06c5748fd3e2641f54bf1599a35'/>
                    </div>
                </div>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Anecdote</h5>
                <h2 className="tech-title">Can I see your code?</h2>
               <p>
                One of my coworkers, a recruiter, came over to the senior engineer sitting next to me to ask him about the interview he had just done. She follows What the Tech on Instagram and had expressed to me how much she enjoys and has learned from it. Before leaving, she leaned over his computer to look at his screen. The senior engineer asked, "What are you doing?" To which she replied, "I wanted to see what your code looks like."
                </p>
                <br/>
                <p>
                I couldn't stop smiling. This was precisely my goal in creating What the Tech. I want to pique people's curiosity about technology and programming, and make it fun and approachable. 
                </p>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Outcome</h5>
                <p>A few months after launching What the Tech, TikTok became the most popular platform for my target audience of young, female teens. Instead of switching platforms and learning the ropes of TikTok, I decided to stick with Instagram since I had already seen a lot of engagement and interest from others outside of this demographic.</p>
                <br/>
                <p>The Instagram account grew to 1,500+ followers, organically, in less than a year.</p>
                <br/>
                <p>I've received many messages from people all over the world with feedback and stories about how the account inspired them to learn to program.</p>
                <br/>
                <p>This account served as a proof of concept for my ideas. I've learned that people do find this content valuable and inspiring, and I'm currently working on the next version of What the Tech which will involve a more suitable and widely accessible format.</p>
                <br/>
                <p>Stay tuned...</p>
            </div>
        </div>
    </div>
    <div className="bottom-nav">
        <Link to="/portfolio/design-thinking">Design Workshops & Consulting</Link>
        <Link to="/portfolio/hackathon">Hack for Olim Hackathon</Link>
        <Link to="/portfolio/kfitzah">Kfitzah</Link>
        <Link to="/portfolio/art">Art</Link>
        <Link to="/">Home</Link>
    </div>
  </Layout>
)

export default WhatTheTech